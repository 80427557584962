import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import AudienceTargetingSec from '../components/Pages/AudienceTargetingSec';

function AudienceTargeting() {
  return (
    <>
    <Helmet>
    <title>Audience targeting | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner main_title="Targeting"  title="Audience targeting" subtitle="Maximize your reach and impact by pinpointing your ideal audience with our precise targeting capabilities." />
    <AudienceTargetingSec />

    </>
  )
}

export default AudienceTargeting