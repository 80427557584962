import React from 'react';
import { useState } from 'react';

function ContactusSec() {

    //   For Name

    const [name, setName] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [nameValidationMessage, setNameValidationMessage] = useState('');

    const handleNameChange = (event) => {
        const value = event.target.value;
        setName(value);
        validateName(value);
    };

    const validateName = (inputName) => {
        const namePattern = /^[A-Za-z]+$/;

        if (inputName === '') {
            setIsValidName(false);
            setNameValidationMessage('Please provide your name.');
        } else if (!namePattern.test(inputName)) {
            setIsValidName(false);
            setNameValidationMessage('Name should contain only letters.');
        } else {
            setIsValidName(true);
            setNameValidationMessage('');
        }
    };

    // Phone Number
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [validationMessages, setValidationMessages] = useState([]);

    const handlePhoneNumberChange = (event) => {
        const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setPhoneNumber(value);
        validatePhoneNumber(value);
    };

    const validatePhoneNumber = (number) => {
        const exactLengthPattern = /^\d{10}$/;

        const messages = [];
        if (number === '') {
            messages.push('Please provide a phone number.');
        }
        if (!exactLengthPattern.test(number)) {
            messages.push('Please enter a valid 10-digit phone number.');
        }

        setIsValid(messages.length === 0);
        setValidationMessages(messages);
    };

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        // Prevent the input of numerical characters
        if (charCode >= 48 && charCode <= 57) {
            event.preventDefault();
        }
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const form = event.target;
    //     if (form.checkValidity() === false) {
    //         event.stopPropagation();
    //     } else {
    //         // Handle form submission
    //         console.log('Form submitted');
    //     }
    //     form.classList.add('was-validated');
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const submitButton = form.querySelector('[type="submit"]');
        const loaderM = document.querySelector('.loaderOverlay');
        loaderM.style.display = 'flex';
        const loader = document.querySelector('.loaderDiv');
        loader.classList.add('loader');
        submitButton.disabled = true;
        // submitButton.insertAdjacentElement('afterend', loader);
    
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            submitButton.disabled = false;
            loader.remove();
            loaderM.remove();
            return;
        }
    
        // Check if all fields are not empty
        const formData = new FormData(form);
        let isValid = true;
        for (let pair of formData.entries()) {
            if (!pair[1]) {
                isValid = false;
                break;
            }
        }
    
        if (!isValid) {
            // If any field is empty
            console.log('All fields are required');
            submitButton.disabled = false;
            loader.remove();
            loaderM.remove();
            return;
        }
    
        // All fields are validated and not empty, proceed with API call
        try {
            const response = await fetch('https://new.programmaticindia.com/createlead.php', {
                method: 'POST',
                body: formData,
                headers: {
                    // You may need to set appropriate headers here based on your API requirements
                }
            });
    
            // Check if response is okay (status code 200)
            if (response.ok) {
                const data = await response.json();
                console.log('API response:', data);
                loader.remove();
                loaderM.remove();

                // Optionally, you can perform further actions here
                // Reset the form after successful submission
                if (data.success) {
                   // Reset the form after successful submission
                form.reset();
                form.classList.remove('was-validated'); // Remove validation class

                // Clear custom validation messages
                const validationElements = form.querySelectorAll('.invalid-feedback, .valid-feedback');
                validationElements.forEach((el) => {
                    el.textContent = '';
                });
    
                    // Show the success popup
                    const successPopup = document.querySelector('.popup');
                    successPopup.style.display = 'flex';
    
                    // Close the popup when the user clicks on <span> (x)
                    const closeBtn = successPopup.querySelector('.close-btn');
                    closeBtn.onclick = () => {
                        successPopup.style.display = 'none';
                        window.location.reload();
                    };
    
                    // Also close the popup when the user clicks anywhere outside of the popup
                    window.onclick = (event) => {
                        if (event.target == successPopup) {
                            successPopup.style.display = 'none';
                            window.location.reload();
                        }
                    };
                }
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error occurred while calling API:', error);
        }
    
        // Enable the button and hide the loader
        submitButton.disabled = false;
        loader.remove();
        loaderM.remove();
    
        // Add 'was-validated' class to the form
        form.classList.add('was-validated');
    };



    return (
        <>
            <div className="InnerBSep"></div>
            <section className='contactInfo bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div
                                className="contactInfoDiv">
                                <h2 className="_h2_m00gf_9 _mt-16_1e3i0_203 _mb-32_1e3i0_315">Head Office</h2>
                                <h5 className="_h5_m00gf_6 _mb-4_1e3i0_295 _color-neutral-600_1d090_266">
                                    Programmatic India Technologies Pvt. Ltd.
                                </h5>
                                <p>Mumbai, Maharashtra</p>
                                <ul className="getInTouchLinks">
                                    <li>
                                        <div className='box location'>
                                            <i className="fa fa-location-dot" aria-hidden="true" />
                                        </div>
                                        <a className="footerParaText" href="https://maps.app.goo.gl/rarHreP3QTX688Jw8" target='_blank'>
                                            Office A-133/5th Floor, Wework, Spectrum Tower, Chincholi Bunder Road,
                                            Mindspace Malad west, Mumbai 400064
                                        </a>
                                    </li>
                                    <li>
                                        <div className='box'>
                                            <i className="fa fa-envelope-o" aria-hidden="true" />
                                        </div>
                                        <p><a href="mailto:info@programmaticindia.com">info@programmaticindia.com</a></p>
                                    </li>
                                    <li>
                                        <div className='box'>
                                            <i className="fa fa-phone" aria-hidden="true" />
                                        </div>
                                        <a href='tel:+91 7977201885' className='footerParaText'>+91 7977201885</a>
                                    </li>
                                </ul>

                            </div>


                        </div>
                        <div className='col-lg-6'>
                            <form className="row g-3 needs-validation contact-form" noValidate onSubmit={handleSubmit}>
                                <input
                                    id="formid"
                                    type="hidden"
                                    value="form1"
                                    className="form-control"
                                    name="form1"
                                    placeholder="Mobile No."
                                />

                                <div className="col-md-6">
                                    <label htmlFor="validationDefault01" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${!isValidName ? 'is-invalid' : ''}`}
                                        id="name"
                                        value={name}
                                        name='name'
                                        onChange={handleNameChange}
                                        onKeyPress={handleKeyPress}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid name.
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="InputEmail1" className="form-label">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        name='email'
                                        className="form-control"
                                        id="InputEmail1"
                                        aria-describedby="emailHelp"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid email address.
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="validationDefault02" className="form-label">
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        name='phone'
                                        className={`form-control ${!isValid ? 'is-invalid' : ''}`}
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a phone number.
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="validationDefault03" className="form-label">
                                        Designation
                                    </label>
                                    <input
                                        type="text"
                                        name='designation'
                                        className="form-control"
                                        id="validationDefault03"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid designation.
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="validationDefault04" className="form-label">
                                        Organisation
                                    </label>
                                    <input
                                        type="text"
                                        name='organisation'
                                        className="form-control"
                                        id="validationDefault04"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid organisation.
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="validationDefault05" className="form-label">
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        name='city'
                                        className="form-control"
                                        id="validationDefault05"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid city.
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        Get in Touch
                                    </button>
                                    <div className='loaderOverlay'>
                                        <div className='loaderDiv'>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                    {/* popup */}
                <div className="popup">
  <div className="popup-inner">
    <button className="close-btn">X</button>
    <h6>
    Thank you! Your inquiry has been submitted successfully.
    </h6>
  </div>
</div>
            </section>
        </>
    );
}

export default ContactusSec;
