import React from 'react';
import mapPoster from '../assets/images/map-poster.png'
import mapPoster2 from '../assets/images/map-poster-2.png'
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function ATC() {

    const videoRef = useRef(null);

    useEffect(() => {
      const videoElement = videoRef.current;
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Video is in view, start autoplay
              videoElement.play();
            } else {
              // Video is out of view, pause autoplay
              videoElement.pause();
            }
          });
        },
        { threshold: 0.5 } // Adjust threshold as needed
      );
  
      // Start observing the video element
      observer.observe(videoElement);
  
      // Clean up observer when component unmounts
      return () => observer.disconnect();
    }, []);

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-12'>
                        <div className='text-center'>
                            <h4 className="display-5 fw-bold primary-title mb-5">Advanced <span>Targeting Capabilities</span> that <br className='break' /> uplift your campaign</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-4 col-xs-12 centerElements'>
                        <div className='section-title'>
                            <h2 className='display-4 fw-bold lightgreen'>Geo <br />Farming</h2>
                        </div>
                        <p className='mt-2 d-none d-lg-block'>Target users based on their historical location visitation behavior.</p>
                        <Link to="/solutions/targeting/location-targeting" class="main-btn btn mt-2 d-none d-lg-block">Know More</Link>
                    </div>
                    <div className='col-lg-8 col-sm-8 col-xs-12'>
                        <div className="overlap-end">
                            <div className="ratio ratio-4x3">
                            <video 
                                ref={videoRef} 
                                className="img-fluid backshadow fullBorder"
                                autoPlay
                                loop
                                muted
                                playsInline
                                controlsList="nodownload"
                                poster={mapPoster}
                                onTouchStart={() => videoRef.current.play()} // Ensure user interaction triggers play
                            >
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming.webm"
                                        type="video/webm"
                                    />
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <div className='borderLayout top'></div>
                            </div>
                            
                            <div className="overlap-content col-lg-7 col-6">
                                <div className='videoRatio'>
                                <video
                                    className="img-fluid shadow-md fullBorder bg-primary-5"
                                    autoPlay=""
                                    muted
                                    loop=""
                                    playsInline=""
                                    controlslist="nodownload"
                                    poster={mapPoster2}
                                    >
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming-ui.webm"
                                        type="video/webm"
                                    />
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming-ui.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <div className='borderLayout bottom'></div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='d-sm-none mt-5'>
                            <p className='pt-4'>Target users based on their historical location visitation behavior.</p>
                            <Link to="/solutions/targeting/location-targeting" class="main-btn btn mt-2">Know More</Link>
                            </div>
                    </div>
                </div>
                <div className="py-0 py-lg-4 my-3 bg-transparent"></div>
            </div>

            

        </>
    )
}

