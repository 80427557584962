import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import SelfServePlatformSec from '../components/Pages/SelfServePlatformSec';

function SelfServePlatform() {
  return (
    <>
    <Helmet>
    <title>Self Serve Platform | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Self Serve Platform" subtitle="Our self-service platform is designed to provide a seamless experience for all your needs." />
    <SelfServePlatformSec />

    </>
  )
}

export default SelfServePlatform