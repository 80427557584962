import React from 'react';
import PSICON1 from '../../assets/images/industry/Healthcareicon-1.png'
import PSICON2 from '../../assets/images/industry/media-consumption-icon.png'
import PSICON3 from '../../assets/images/industry/Healthcareicon-2.png'
import PSICON4 from '../../assets/images/industry/Healthcareicon-3.png'


function HealthcareSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='HealthcareSec boxDiv bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="cardDiv relative">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">Healthcare Informed & Less Health conscious</h3>
                                    <img src={PSICON1}
                                        alt="Capture Audience" loading="lazy" width="100%" />
                                    <div className="">
                                        <p>Health conscious consumers in urban areas. They are well accustomed to the benefits of preventive healthcare. Also people with distinctive health consciousness and those not showing much interest for preventive health care. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Visitation
                                    </h3>
                                    <img src={PSICON3}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target audience who visit hospitals, speciality clinics, diagnostic centres and pharmacies.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Target HCP’s
                                    </h3>
                                    <img src={PSICON4}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target HCP’s based on their speciality </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Media Consumption
                                    </h3>
                                    <img src={PSICON2}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target devices seen with medical, phramacy & healthcare apps as well as devices consuming content related to diseases, health conditions and medicines.  </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default HealthcareSec;
