import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import OptimizationSec from '../components/Pages/OptimizationSec';

function Optimization() {
  return (
    <>
    <Helmet>
    <title>Optimization | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Optimization" subtitle="Reduce media waste with PI's proprietary patent-pending AI creative technology, which abstracts the contextual data from the advertiser's creatives and matches that with curated ad inventory and audiences for optimal performance." />
    <OptimizationSec />

    </>
  )
}

export default Optimization