import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import PartnersSec from '../components/Pages/PartnersSec';

function Partners() {
  return (
    <>
    <Helmet>
    <title>Partners | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Partners" subtitle="Programmatic India is a world class global RTB DSP with access to 1.4 Million plus publishers on the open internet (95% of all open internet publishers) and custom audience targeting capability with 91.5 crore strong audience data." />
    <PartnersSec />

    </>
  )
}

export default Partners