import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import ContextualTargetingSec from '../components/Pages/ContextualTargetingSec';

function ContextualTargeting() {
  return (
    <>
    <Helmet>
    <title>Contextual Targeting | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner main_title="Targeting"  title="Contextual Targeting" subtitle="Target users by the content they are consuming or have consumed in the past. PI's proprietary AI technology monitors ad calls across virtually every news and information site, captures user and content data, analyzes the data in real time, and creates targeting models based on both historical and most recent content consumption." />
    <ContextualTargetingSec />

    </>
  )
}

export default ContextualTargeting