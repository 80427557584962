import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import BFSISec from '../components/Pages/BFSISec';
function BFSI() {
  return (
    <>
    <Helmet>
    <title>BFSI | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Helping Banking & Finance Advertisers to reach their desired audience    
" subtitle="A Media Buying platform for BFSI advertising " />
    <BFSISec />

    </>
  )
}

export default BFSI