import React, { useState, useEffect, useRef } from 'react';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

function Navigation() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [activeLink, setActiveLink] = useState('Home');
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const navRef = useRef(null);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    setOpenSubMenu(null); // Close sub-menus when toggling main nav
  };

  const handleNavLinkClick = (linkName) => {
    setActiveLink(linkName);
    setIsNavCollapsed(true);
    setOpenSubMenu(null); // Close sub-menus when a main link is clicked
  };

  const handleOutsideClick = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavCollapsed(true);
      setOpenSubMenu(null); // Close sub-menus when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleSubMenu = (event, link) => {
    event.preventDefault(); // Prevent default link behavior
    setOpenSubMenu(openSubMenu === link ? null : link); // Toggle sub-menu
  };

  const closeMenu = () => {
    setIsNavCollapsed(true);
    setOpenSubMenu(null); // Close sub-menus when a submenu link is clicked
  };

  return (
    <nav className="prograIn-navbar navbar p-0 navbar-expand-lg navbar-light">
      <div className="container">
        <div className="main-menu" ref={navRef}>
          <Link className="custom-nav-logo navbar-brand" to="/">
            <img src={Logo} alt="Programmatic India Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`}
            id="navbarNav"
          >
            <a href="#" className="CloseNavigationMobile" onClick={handleNavCollapse}>
              <i className="fa-solid fa-xmark"></i>
            </a>
            <ul className="navbar-nav ml-auto">
              {['About us', 'Solutions', 'Platform', 'Industry', 'Why Programmatic India'].map((link) => (
                <li
                  key={link}
                  className={`customNavLink nav-item ${activeLink === link ? 'active' : ''} ${['Solutions'].includes(link) ? 'mega-menu' : ''}`}
                  onClick={() => !['Solutions', 'Platform', 'Industry', 'Rules & FAQs'].includes(link) && handleNavLinkClick(link)}
                  onMouseEnter={() => setOpenSubMenu(link)}
                  onMouseLeave={() => setOpenSubMenu(null)}
                >
                  <Link
                    className="nav-link"
                    to={link === 'About us' ? '/about' : `/${link.toLowerCase().replace(/\s+/g, '-')}`}
                    onClick={(e) => ['Solutions', 'Platform', 'Industry', 'Rules & FAQs'].includes(link) && toggleSubMenu(e, link)}
                  >
                    {link}
                    {(link === 'Solutions' || link === 'Platform' || link === 'Industry' || link === 'Rules & FAQs') &&
                      <i className="fa-solid fa-chevron-down ml-1"></i>}
                  </Link>
                  {/* Add sub-menu here */}
                  {link === 'Solutions' && openSubMenu === 'Solutions' && (
                    <div className="megamenu">
                    <div className="row">
                      <div className="col">
                        <h5>Planning</h5>
                        <ul>
                          <li><Link to="/solutions/planning/audience-planning" onClick={closeMenu}>
                            <svg
                              focusable="false"
                              className=""
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="NavPlanningIcon"
                              style={{ fill: "none" }}
                            >
                              <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
                                <path
                                  stroke="var(--primaryColor, #ff5854)"
                                  d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a1.9999 1.9999 0 0 0-2-2h-2"
                                />
                                <path
                                  stroke="var(--brandOrange,#FFA300)"
                                  d="M13 3h-2c-1.1046 0-2 .8954-2 2s.8954 2 2 2h2c1.1046 0 2-.8954 2-2s-.8954-2-2-2Zm-4 9h.01"
                                />
                                <path stroke="var(--brandRed,#FF5859)" d="M13 12h2" />
                                <path stroke="var(--brandOrange,#FFA300)" d="M9 16h.01" />
                                <path stroke="var(--brandRed,#FF5859)" d="M13 16h2" />
                              </g>
                              <defs />
                            </svg>
                            <p>Audience Planning</p></Link></li>
                        </ul>
                      </div>
                      <div className="col">
                        <h5>Targeting</h5>
                        <ul>
                          <li><Link to="/solutions/targeting/audience-targeting" onClick={closeMenu}>
                            <svg
                              focusable="false"
                              className=""
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="NavAudienceTargetingIcon"
                              style={{ fill: "none" }}
                            >
                              <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
                                <path
                                  stroke="var(--brandOrange,#FF5859)"
                                  d="M7 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Z"
                                />
                                <path
                                  stroke="var(--brandGreen,#ff5854)"
                                  d="M5 22v-5l-1-1v-4c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h4c.3 0 .5.1.7.3.2.2.3.4.3.7v4l-1 1v5"
                                />
                                <path
                                  stroke="var(--brandRed,#FF5859)"
                                  d="M17 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Z"
                                />
                                <path
                                  stroke="var(--brandGreen,#ff5854)"
                                  d="M15 22v-4h-2l2-6c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h2c.3 0 .5.1.7.3.2.2.3.4.3.7l2 6h-2v4"
                                />
                              </g>
                              <defs />
                            </svg>

                            <p>Audience Targeting</p></Link></li>
                          <li><Link to="/solutions/targeting/location-targeting" onClick={closeMenu}>
                            <svg
                              focusable="false"
                              className="_root_1eapt_1 _iconColor-inherit_1eapt_57 _fontSize-inherit_1eapt_11"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="NavLocationTargetingIcon"
                              style={{ fill: "none" }}
                            >
                              <path
                                stroke="var(--brandGreen,#ff5854)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="m18 12.9996-3.5-5c-.3-.6-.5-1.3-.5-2s.2-1.4.6-2c.4-.6.9-1.1 1.5-1.4.6-.3 1.3-.5 2-.5s1.4.2 2 .5c.6.3 1.1.8 1.5 1.4.2.6.4 1.3.4 2s-.2 1.4-.5 2l-3.5 5Z"
                              />
                              <path
                                stroke="var(--brandRed,#ff5854)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10.5 4.8 9 4 3 7v13l6-3 6 3 6-3v-2M9 4v13m6-2v5"
                              />
                              <circle cx={18} cy={6} r="1.5" fill="#ff5854" />
                            </svg>
                            <p>Location Targeting</p></Link></li>
                          <li><Link to="/solutions/targeting/contextual-targeting" onClick={closeMenu}>
                            <svg
                              focusable="false"
                              className=""
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="NavContexualTargetingIcon"
                              style={{ fill: "none" }}
                            >
                              <path
                                stroke="var(--brandOrange,#ff5854)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5H5c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1Z"
                              />
                              <path
                                stroke="var(--brandGreen,#ff5854)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M14 7h6m-6 4h6M4 15h16M4 19h16"
                              />
                            </svg>

                            <p>Contextual Targeting</p></Link></li>
                        </ul>
                      </div>
                      <div className="col">
                        <h5>Optimization</h5>
                        <ul>
                          <li><Link to="/solutions/optimization" onClick={closeMenu}><svg
                            focusable="false"
                            className="_root_1eapt_1 _iconColor-inherit_1eapt_57 _fontSize-inherit_1eapt_11"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="NavOptimizationIcon"
                            style={{ fill: "none" }}
                          >
                            <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
                              <path
                                stroke="var(--brandGreen,#ff5854)"
                                d="M20 8h-6c-.5523 0-1 .4477-1 1v10c0 .5523.4477 1 1 1h6c.5523 0 1-.4477 1-1V9c0-.5523-.4477-1-1-1Z"
                              />
                              <path
                                stroke="var(--brandOrange,#ff5854)"
                                d="M18 8V5a1.0002 1.0002 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9m3-9h2"
                              />
                            </g>
                            <defs />
                          </svg>
                            <p>Optimization</p></Link></li>

                        </ul>
                      </div>
                    </div>
                  </div>
                  )}
                {link === 'Platform' && openSubMenu === 'Platform' && (
  <ul className="sub-menu">
    <li>
      <Link to="/platform/self-serve-platform" onClick={closeMenu}>
        <svg
          focusable="false"
          className=""
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="NavSelfServeIcon"
          style={{ fill: "none" }}
        >
          <path
            stroke="var(--brandGreen,#ff5854)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 11V9a1.9999 1.9999 0 0 0-2-2H9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2"
          />
          <path
            stroke="var(--brandGreen,#ff5854)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m13 13 9 3-4 2-2 4-3-9ZM3 3v.01M7 3v.01M11 3v.01M15 3v.01M3 7v.01M3 11v.01M3 15v.01"
          />
        </svg>
        <p>Self Serve</p>
      </Link>
    </li>
    <li>
      <Link to="/platform/our-technologies" onClick={closeMenu}>
        <svg
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="NavOurTechnologiesIcon"
          style={{ fill: "none" }}
        >
          <g
            stroke="var(--brandGreen,#ff5854)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          >
            <path d="M13.4023 1.7998v6.8h2.7m1.5985 1.5c.7732 0 1.4-.6268 1.4-1.4 0-.7732-.6268-1.4-1.4-1.4-.7732 0-1.4.6268-1.4 1.4 0 .7732.6268 1.4 1.4 1.4Zm-4.2985 9.3998v-6.8c2.1 0 4.2 0 6.3-.1M21.4 14.0002c.7732 0 1.4-.6268 1.4-1.4 0-.7732-.6268-1.4-1.4-1.4-.7732 0-1.4.6268-1.4 1.4 0 .7732.6268 1.4 1.4 1.4ZM10.8 4.7998v6.8c-2.1 0-4.2 0-6.3.1m-.4992-.0996c0 .8-.6 1.4-1.3 1.4-.7 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.3.7 1.3 1.4Zm6.8008 10.6002v-6.8h-2.7M7.8 15.4c0-.7-.6-1.4-1.4-1.4-.8 0-1.4.6-1.4 1.4 0 .7.6 1.4 1.4 1.4.8 0 1.4-.7 1.4-1.4Z" />
          </g>
        </svg>
        <p>Our Technologies</p>
      </Link>
    </li>
    {/* <li>
      <Link to="#" onClick={closeMenu}>
      <svg
      className="_root_1eapt_1 _iconColor-inherit_1eapt_57 _fontSize-inherit_1eapt_11"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ApiIcon"
      fill="none"
    >
      <path
        stroke="var(--brandGreen,#ff5854)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13.426 13L10.3 20.5M8 14l-2 2.5L8 19m8 0l2-2.5-2-2.5"
      />
      <path
        stroke="var(--brandGreen,#ff5854)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 16.025l-.622-.293C2.496 14.888 2 13.743 2 12.55s.496-2.338 1.378-3.182A4.815 4.815 0 016.705 8.05C6.999 6.737 7.86 5.584 9.1 4.843a5.972 5.972 0 012.025-.749 6.224 6.224 0 012.19.006c.722.131 1.408.39 2.02.76a5.36 5.36 0 011.543 1.397c.408.552.69 1.172.832 1.823a4.554 4.554 0 01-.006 1.97h1a3.5 3.5 0 012.474 5.975L20 17"
      />
    </svg>
        <p>Platform API</p>
      </Link>
    </li> */}
  </ul>
)}

                  {link === 'Industry' && openSubMenu === 'Industry' && (
                    <ul className="sub-menu">
                      <li><Link to="/industry/political" onClick={closeMenu}>Political</Link></li>
                      <li><Link to="/industry/healthcare" onClick={closeMenu}>Healthcare</Link></li>
                      <li><Link to="/industry/travel" onClick={closeMenu}>Travel</Link></li>
                      <li><Link to="/industry/BFSI" onClick={closeMenu}>BFSI</Link></li>
                      <li><Link to="/industry/retail" onClick={closeMenu}>Retail</Link></li>
                      <li><Link to="/industry/ambient-media" onClick={closeMenu}>OOH & Ambient Media</Link></li>
                    </ul>
                  )}
                  {link === 'Rules & FAQs' && openSubMenu === 'Rules & FAQs' && (
                    <ul className="sub-menu">
                      <li><a href="#" onClick={(e) => e.stopPropagation()}>Online Competition Rules</a></li>
                      <li><a href="#" onClick={(e) => e.stopPropagation()}>General Competition Rules</a></li>
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;