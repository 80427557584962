import React from 'react';
import kentrixLogo from '../../assets/images/platform/Kentrix_logo.jpg'
import kentrixEdge from '../../assets/images/platform/KentrixEdge.jpg'

function PartnersSec() {
    return (
        <>
        <div className="InnerBSep"></div>
            <section className='partnerSec bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                                
                            <p>Programmatic India has an exclusive partnership with Kentrix to help advertisers get a 360 degree view of their customers.</p>
                            <p>The PI + Kentrix partnership enables you to enrich your first party data, understand existing customers and their behavior, create insights and reach the desired audience segments efficiently.</p>
                            <p>Kentrix is an <strong>Inclusive Business Intelligence Platform</strong> for consumer data-driven solutions, operating on data of 91.5 crore Indian consumers.</p>
                          
                        </div>
                        <div className='col-lg-4'>
                            <div className='parner_logo'>
                            <img className="img-responsive " src={kentrixLogo} width="100%" />
                            </div>
                        </div>
                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <img src={kentrixEdge} alt='' width="100%" />
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default PartnersSec;
