import React from 'react';
import Optimization from '../../assets/images/platform/Optimization_1.svg'

function OptimizationSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='SSPSec bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">AI-driven effectiveness</h2>
                                </div>
                                <p>Based on real-world data, our AI-powered platform is designed to test, identify and scale top-performing ad units dynamically. Our machine learning technology ensures that every bid is optimized towards creative, frequency, Location, and more for lowering cost and driving more effective media.</p>
                                <ul className="">
                                    <li className="aK6P7">

                                        <p>
                                        Scale top-performing ad units
                                        </p>
                                    </li>
                                    <li className="aK6P7">

                                        <p>
                                        Optimise every bids
                                        </p>
                                    </li>
                                    <li className="aK6P7">

                                        <p>
                                        Lower your cost and drive more effective media
                                        </p>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={Optimization} alt='' width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OptimizationSec;
