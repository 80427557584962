import React from 'react';
function InnerpageBanner({ title, subtitle, main_title }) {
    return (
        <>
            <section className="InnerBanner gee1I RLCPV Xhbcm DYwTs" >
                <svg width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg"><defs><pattern id="square" width="64" height="64" viewBox="0 0 64 64" patternUnits="userSpaceOnUse"><rect opacity="0.5" x="1" y="1" width="64" height="64" stroke="#123787" stroke-width="0.15" stroke-dasharray="5 5"></rect></pattern></defs><rect x="0" y="0" width="100%" height="100%" fill="url(#square)"></rect></svg>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="">
                                <h2 className="">{main_title} </h2>
                                <h1 className="mb-3">{title}</h1>
                                <h4>{subtitle}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='odzpY'>
                </div>
                {/* <img
    src="https://cdn.sanity.io/images/xm425c9d/production/5644a6dec44ea7c3220a91dbfd5a6a3d9dcef55d-4250x4250.png?fm=webp"
    className="odzpY"
  /> */}
            </section>
        </>
    );
}
export default InnerpageBanner;