import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import PoliticalSec from '../components/Pages/PoliticalSec';
function Political() {
  return (
    <>
    <Helmet>
    <title>Political | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Helping Political Advertisers to reach their desired audience  
" subtitle="A media buying platform for intelligent Political Advertising" />
    <PoliticalSec />

    </>
  )
}

export default Political