import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import ContactusSec from '../components/Pages/ContactusSec';

function Contact() {
  return (
    <>
    <Helmet>
    <title>Contact us | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Contact us" subtitle="We appreciate your interest in reaching out to us. Our dedicated team is fully committed to delivering the utmost level of service and is readily available to address any inquiries or concerns you may have." />
    <ContactusSec />

    </>
  )
}

export default Contact