import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import RetailSec from '../components/Pages/RetailSec';
function Retail() {
  return (
    <>
    <Helmet>
    <title>Retail | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Helping Retail Advertisers to reach their desired audience  
" subtitle="A media buying platform for intelligent Retail Advertising" />
    <RetailSec />

    </>
  )
}

export default Retail