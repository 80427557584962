import React from 'react';
import Targeting from '../../assets/images/platform/Targeting.webp'
import Analyzing from '../../assets/images/platform/Analyzing.webp'
import Capturing from '../../assets/images/platform/Capturing.webp'


function ContextualTargetingSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='CTSec bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-start">
                                    <h3 className="fw-bold">Capturing</h3>
                                    <img src={Capturing} className='my-2'
                                        alt="Capture Audience" loading="lazy" width="100%"  />
                                    <div className="">
                                        <p>Programmatic India captures data as users browse websites and are served ads across the
                                            web.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-start">
                                    <h3 className="fw-bold">
                                        Analyzing
                                    </h3>
                                    <img src={Analyzing} className='my-2'
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <ul>
                                            <li>
                                                <span>Contextual</span>
                                            </li>
                                            <li>
                                                <span>Sentiment</span>
                                            </li>
                                            <li>
                                                <span>Semantic</span>
                                            </li>
                                            <li>
                                                <span>Demographic</span>
                                            </li>
                                            <li>
                                                <span>Behavioral</span>
                                            </li>
                                            <li>
                                                <span>Consumption Patterns</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-start">
                                    <h3 className="fw-bold">
                                        Targeting
                                    </h3>
                                    <img src={Targeting} className='my-2'
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p className="_body-medium_m00gf_54 _mb-16_1e3i0_307">Target User:</p>
                                        <ul className="uMNgU">
                                            <li className="xm8wk">
                                                <span className="_body-medium_m00gf_54">In real-time</span>
                                            </li>
                                            <li className="xm8wk">
                                                <span className="_body-medium_m00gf_54">
                                                    Looking back up to 12 months
                                                </span>
                                            </li>
                                        </ul>
                                        <p className="_body-medium_m00gf_54 _mb-16_1e3i0_307">
                                            <br />
                                            Target Context:
                                        </p>
                                        <ul className="uMNgU">
                                            <li className="xm8wk">
                                                <span className="_body-medium_m00gf_54">In real-time</span>
                                            </li>
                                            <li className="xm8wk">
                                                <span className="_body-medium_m00gf_54">
                                                    Looking back up to 12 months
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default ContextualTargetingSec;
