import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import HealthcareSec from '../components/Pages/HealthcareSec';
function Healthcare() {
  return (
    <>
    <Helmet>
    <title>Healthcare | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Helping Healthcare Advertisers to reach their desired audience" subtitle="A media buying platform for intelligent Healthcare Advertising" />
    <HealthcareSec />

    </>
  )
}

export default Healthcare