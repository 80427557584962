import React from 'react';
import PSICON1 from '../../assets/images/industry/Travelicon-1.png'
import PSICON2 from '../../assets/images/industry/media-consumption-icon.png'
import PSICON3 from '../../assets/images/industry/Travelicon-2.png'
import PSICON4 from '../../assets/images/industry/Travelicon-3.png'


function TravelSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='TravelSec boxDiv bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="cardDiv relative">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">Travelers Category</h3>
                                    <img src={PSICON1}
                                        alt="Capture Audience" loading="lazy" width="100%" />
                                    <div className="">
                                        <p>Target Travellers based on travel history, and by mode of travel basic, business and luxury.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    International Traveler
                                    </h3>
                                    <img src={PSICON3}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Users who have travelled internationally and to exotic destinations and luxury travel destinations</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Visitation
                                    </h3>
                                    <img src={PSICON4}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target people based on their type of hotel visitations, Basic, 3-4 & 5 star hotels, Luxury hotels etc.</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Media Consumption
                                    </h3>
                                    <img src={PSICON2}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Devices seen with the travel apps, Reading articles about Travel  destinations, Travel packages, Experiences  etc. </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default TravelSec;
