import React from 'react';
import pl_icon from '../../assets/images/pl-icon-72x72.png'
import iqmLogo from '../../assets/images/iqm-logo.png'
import daa from '../../assets/images/daa.png'
import mma from '../../assets/images/mma.png'
import startup from '../../assets/images/startup.png'
import AIImg from '../../assets/images/platform/Audience-Intelligence.svg'
import LIIMG from '../../assets/images/platform/Location-Intelligence.png'
import DSPIMG from '../../assets/images/platform/Data-Security-Privacy.svg'
import Optimization from '../../assets/images/platform/Optimization.svg'
import DAA from '../../assets/images/partners/DAA.svg';
import GDPR from '../../assets/images/partners/GDPR.svg';
import CCPA from '../../assets/images/partners/CCPA.svg';
import iab from '../../assets/images/partners/iab.svg';
import iab_europ from '../../assets/images/partners/iab_europe.svg';
import GTA from '../../assets/images/partners/Google Tag Manager.svg';
import PA from '../../assets/images/partners/Political AdChoices.svg';
import AdChoices from '../../assets/images/partners/AdChoices.svg';
import AIC from '../../assets/images/partners/Advertising ID Consortium.svg';

function OurTechnologiesSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='platformSec bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div class="text-left">
                                <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Data Security & Privacy</h2>
                            </div>

                            <p>We use strong data protection methods to anonymize and protect all campaign data, including Personal Identifiable Information (PII), and strictly follow the policies of the Digital Advertising Alliance (DAA). We also support election firewalls and comply with our political campaign clients' established firewalls to ensure complete confidentiality.</p>
                            <div className='partnersBrand-logo'>
                                <div className='item'>
                                    <img src={CCPA} alt='CCPA' />
                                </div>
                                <div className='item'>
                                    <img src={GDPR} alt='GDPR' />
                                </div>
                                <div className='item'>
                                    <img src={DAA} alt='Digital Advertising Alliance' />
                                </div>
                                <div className='item'>
                                    <img src={AIC} alt='Advertising ID Consortium' />
                                </div>
                                <div className='item'>
                                    <img src={PA} alt='Political AdChoices' />
                                </div>
                               
                                <div className='item'>
                                    <img src={iab} alt='iab' />
                                </div>
                                <div className='item'>
                                    <img src={iab_europ} alt='iab.europe' />
                                </div>
                                <div className='item'>
                                    <img src={AdChoices} alt='AdChoices' />
                                </div>
                               
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={DSPIMG} alt='' width="600" height="400" />
                            </div>
                        </div>
                    </div>
                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row divReverse'>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={AIImg} alt='' width="600" height="400" />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Audience Intelligence</h2>
                                </div>
                                <p>Programmatic India provides a secure AI-powered platform for Audience data, insights, and campaign analysis, to help you gain a deeper understanding of audience behavior. Track, measure, and optimize your campaigns with accurate data and real-time analysis.Use our hyper-focused insights to gain a deeper understanding of your target audience and build an addressable audience based on your campaign goals.</p>
                            </div>
                        </div>

                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Location Intelligence</h2>
                                </div>
                                <p>Programmatic India AI-powered platform uses machine learning to reach your audiences accurately and provides the best possible ROI for your campaigns. Our advanced geo-targeting capabilities like geo-fencing and geo-farming help you reach your target audience with enhanced accuracy.</p>
                                <h4 className='my-3'>
                                    Programmatic India DSP Platform - Location Intelligence</h4>
                                <p>
                                    Geofencing Targeting - Create a polygon (virtual boundary) on the map and target audience in real-time when they visit the location.
                                    Geofarm Targeting - Identify your target audience by their historical visitation behavior and target them with relevant ads.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={LIIMG} alt='' width="600" height="400" />
                            </div>
                        </div>

                    </div>


                </div>
            </section>

            <section className='platformSec gradientGrayBg'>
                <div className='container'>
                    <div className='row divReverse'>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={Optimization} alt='' width="600" height="400" />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Optimization</h2>
                                </div>

                                <p>Bid Optimization in RTB. Programmatic India delivers fast, efficient, and cost-effective ad serving with advanced machine learning and AI capabilities that optimize every bid in real-time. Our servers are designed to scale top-performing ad units to reach your target audience quickly. We prioritize low-latency service delivery, knowing that milliseconds can make a difference in ad viewability. Our technology considers various factors, such as inventory type and publisher, to ensure that you reach the right audience with the right message at a fair price. Our machine learning and AI cognitive capabilities predict audience engagement with your creatives, allowing real-time customization to improve engagement, campaign performance, and ROI.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    );
}

export default OurTechnologiesSec;
