import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import AudiencePlanningSec from '../components/Pages/AudiencePlanningSec';

function AudiencePlanning() {
  return (
    <>
    <Helmet>
    <title>Audience Planning | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner main_title="Planning"  title="Audience Planning" subtitle="Optimize your advertising spend and drive better results with our audience planning feature, designed to help your brand reach the right people at the right time." />
    <AudiencePlanningSec />

    </>
  )
}

export default AudiencePlanning