import React from 'react';
import ContextualBackImg from '../assets/images/Contextual-back-img.png'
import ContextualFrontImg from '../assets/images/Contextual-front-img.png'
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Contextual() {

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 col-sm-8 col-xs-12'>
                    <div className='section-title d-sm-none'>
                            <h2 className='display-4 fw-bold lightgreen'>Contextual</h2>
                        </div>
                        <div className="overlap-end">
                            <div className="ratio ratio-4x3">
                                <img src={ContextualBackImg} className='backImg backshadow border1' alt='' />
                                <div className='borderLayout top'></div>
                            </div>
                            <div className="overlap-content col-lg-7 col-6">
                                <div className='videoRatio'>
                                <video
                                    className="img-fluid shadow-md fullBorder bg-primary-5"
                                    autoPlay=""
                                    loop=""
                                    muted=""
                                    playsInline=""
                                    controlslist="nodownload"
                                    poster={ContextualFrontImg}
                                    >
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming-ui.webm"
                                        type="video/webm"
                                    />
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming-ui.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <div className='borderLayout bottom'></div>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-4 col-sm-4 col-xs-12 centerElements'>
                        <div className='section-title d-none d-lg-block'>
                            <h2 className='display-4 fw-bold lightgreen'>Contextual</h2>
                        </div>
                        <p className='mt-2'>Target users by the content they are consuming or have consumed in the past.</p>
                        <Link to="/solutions/targeting/contextual-targeting" class="main-btn btn mt-2">Know More</Link>
                    </div>
                </div>
                <div className="py-0 py-lg-4 my-3 bg-transparent"></div>
            </div>
        </>
    )
}

