import React from 'react';
import pl_icon from '../../assets/images/pl-icon-72x72.png'
import iqmLogo from '../../assets/images/iqm-logo.png'
import daa from '../../assets/images/daa.png'
import mma from '../../assets/images/mma.png'
import startup from '../../assets/images/startup.png'

function AboutPageSec() {
    return (
        <>
        <div className="InnerBSep"></div>
            <section className='aboutInfo gradientGrayBg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                                <div className="logopng">
						              <img className="img-responsive" src={pl_icon} />
                                      <span>+</span>
						              <img className="img-responsive" src={iqmLogo} />
						           </div>
                            <p><b>Programmatic India [PI] Pvt. Ltd.</b> is a programmatic Demand Side  Platform [DSP] - the exclusive license-holder and partner of <b>IQM  Corporation</b> in India.</p>
                            <p><b>IQM</b> has been ranked #5 among the leading U.S. advertising and marketing corporations in 2019* and was  #21 in the fastest growing companies in the U.S.* in 2020. It offers an Omni-channel digital  media buying platform that supports AI-driven cross-device, cross-  format advertising campaigns precisely targeting the right consumers.</p>
                            <p><b>Programmatic India</b> was ranked amongst the 10 top ad-tech startups in india for 2022*.</p>
                            <i><small>*Inc.500, Financial Times and Silicon India</small></i>
                            {/* <div className="logopng bottom mt-4">
						               <img className="img-responsive pe-4" src={daa} />
						              <img className="img-responsive" src={mma} />
						           </div> */}
                        </div>
                        <div className='col-lg-6'>
                            <div className='row abtCollabLogo'>
                            <div className='col-lg-12 col-xs-4 mb-4 col-4'>
                            <img className="img-responsive" src={startup} />
                            </div>
                            <div className='col-lg-6 col-xs-4 mb-4 col-4'>
                            <img className="img-responsive" src={daa} />
                            </div>
                            <div className='col-lg-6 col-xs-4 mb-4 col-4'>
                            <img className="img-responsive" src={mma} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutPageSec;
