import React from 'react';
import PSICON1 from '../../assets/images/industry/AMicon-1.png'
import PSICON2 from '../../assets/images/industry/AMicon-2.png'
import PSICON3 from '../../assets/images/industry/AMicon-3.png'
import PSICON4 from '../../assets/images/industry/AMicon-4.png'


function OOHAmbientMediaSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='OOHAmbientMediaSec boxDiv bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="cardDiv relative">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">Full OOH campaign Analytics</h3>
                                    <img src={PSICON1}
                                        alt="Capture Audience" loading="lazy" width="100%" />
                                    <div className="">
                                        <p>Get measurement metrics such as number of devices seen passing the OOH site, along with insights into income, place of residence, behavioural attributes etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Audience Retargeting On Mobile
                                    </h3>
                                    <img src={PSICON2}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Retarget devices exposed to OOH message using our Programmatic India Platform through Display, Video & Audio creatives for better engagement. </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Audience Expansion
                                    </h3>
                                    <img src={PSICON3}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Extend OOH campaign to look alike audiences based on  profile of devices seen passing OOH location.</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Measure OOH Campaign Effectiveness
                                    </h3>
                                    <img src={PSICON4}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Programmatic Retargeting of devices seen at OOH campaign locations with ad creatives using strong CTA can measure OOH campaign effectiveness</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default OOHAmbientMediaSec;
