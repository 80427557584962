import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import OOHAmbientMediaSec from '../components/Pages/OOHAmbientMediaSec';
function OOHAmbientMedia() {
  return (
    <>
    <Helmet>
    <title>OOH & Ambient Media | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Helping OOH & Ambient Media Advertisers to reach their desired audience" subtitle="A Media Buying platform for OOH & Ambient Media advertisers
" />
    <OOHAmbientMediaSec />

    </>
  )
}

export default OOHAmbientMedia