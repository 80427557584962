import React from 'react';
import LTGFTIMG from '../../assets/images/platform/LTGFTIMG.png'
import TVIIMG from '../../assets/images/platform/TVIIMG.png'


function LocationTargetingSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='SSPSec bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='discription'>
                            <div class="text-left">
                                <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Geo-fencing Targeting</h2>
                            </div>

                            <p>Create polygons (virtual boundaries) on the map and target users in real-time when they visit the desired locations</p>
                            <ul className="">
                                <li className="aK6P7">
                                    
                                    <p>
                                    Virtual boundaries for precise targeting.
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Timely engagement with real-time targeting.
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Target beyond zip-code level
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={LTGFTIMG} alt='' width="100%" />
                            </div>
                        </div>
                    </div>


                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row divReverse'>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={TVIIMG} alt='' width="100%"  />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Target by visitation intelligence</h2>
                                </div>
                                <p>By leveraging Programmatic India's exclusive data partnerships, you can target audience based on historical visitation behaviour.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Identify audiences based on their visitations of specific locations such as office zones or workspaces.
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Create a customised segment of these audiences
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Reach out to these audience on connected devices with customised messaging
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>

                    </div>

                    {/* <div className="gnagt">
                        <hr className="" />
                    </div> */}

                    {/* <div className='row'>

                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4">Consolidated Reporting</h2>
                                </div>
                                <p>Easily track campaign performance across devices and mediums with consolidated reporting. Optimize in real-time to get the best performance for your ad spend.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Cross-device tracking: Track performance across devices
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Consolidated reporting: Easy-to-read campaign dashboard
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Actionable insights: Data-driven decision-making
                                    </p>
                                </li>
                                <li className="aK6P7">   
                                    <p>
                                    Customizable reporting: Flexible reporting options
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={CRIMG} alt='' width="100%"  />
                            </div>
                        </div>

                    </div> */}


                </div>
            </section>

            {/* <section className='SSPSec gradientGrayBg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='SSPImg'>
                                <img src={RTOIMG} alt='' width="100%" height="" />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4">Real-time Optimization</h2>
                                </div>

                                <p>Our AI-powered ad servers optimize every bid in real-time using advanced machine learning, ensuring that you reach your desired audience with the right message at a fair price.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    AI-powered ad servers: Optimized bidding & bid Shading
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Real-time optimization: Multi-factor optimization
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Increased ROI: Improved return on investment
                                    </p>
                                </li>
                                <li className="aK6P7">   
                                    <p>
                                    Continuous improvement: Learning and optimizing continuously
                                    </p>
                                </li>
                                <li className="aK6P7">   
                                    <p>
                                    Proactive monitoring: Monitoring for smooth performance
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>

                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>
                        
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4">24X7 AdOps Support</h2>
                                </div>

                                <p>Get expert advice and support from our team of programmatic experts, whether you use our self-serve platform or managed services, to ensure your campaign goals are met.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Personalized guidance for campaign goals
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Optimize targeting, messaging, and creative
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Ongoing support for campaign success
                                    </p>
                                </li>
                                <li className="aK6P7">   
                                    <p>
                                    Industry insights for better campaigns
                                    </p>
                                </li>
                                <li className="aK6P7">   
                                    <p>
                                    Monitor and analyze campaign performance
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='SSPImg'>
                                <img src={RTOIMG} alt='' width="100%" height="" />
                            </div>
                        </div>

                    </div>

                </div>
            </section> */}
        </>
    );
}

export default LocationTargetingSec;
