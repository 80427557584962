import React from 'react';
import PSICON1 from '../../assets/images/industry/look-like-icon.png'
import PSICON2 from '../../assets/images/industry/media-consumption-icon.png'
import PSICON3 from '../../assets/images/industry/polygons-icon.png'
import PSICON4 from '../../assets/images/industry/voter-list-icon.png'


function PoliticalSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='PoliticalSec boxDiv bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="cardDiv relative">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">Voter List</h3>
                                    <img src={PSICON4}
                                        alt="Capture Audience" loading="lazy" width="100%" />
                                    <div className="">
                                        <p>Define your desired voter profile, Upload custom list matching your exact voter persona and target them </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Look-alike
                                    </h3>
                                    <img src={PSICON1}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Create look-alike audience of your voter list to target them</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Constituencies
                                    </h3>
                                    <img src={PSICON3}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Create custom polygons around Constituencies and Wards or upload pin codes to target voters.</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Media Consumption
                                    </h3>
                                    <img src={PSICON2}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target audiences based on media consumption of trending topics, issues and political affinity. </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default PoliticalSec;
