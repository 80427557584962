import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import LocationTargetingSec from '../components/Pages/LocationTargetingSec';

function LocationTargeting() {
  return (
    <>
    <Helmet>
    <title>Location Targeting | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner main_title="Targeting"  title="Location Targeting" subtitle="Reach the right people at the right time and place with our powerful location targeting tool." />
    <LocationTargetingSec />

    </>
  )
}

export default LocationTargeting