import React from 'react';
import { useState } from 'react';
import footerLogo from '../../assets/images/footer-logo.png';
import DAA from '../../assets/images/partners/DAA.svg';
import GDPR from '../../assets/images/partners/GDPR.svg';
import CCPA from '../../assets/images/partners/CCPA.svg';
import iab from '../../assets/images/partners/iab.svg';
import iab_europ from '../../assets/images/partners/iab_europe.svg';
import GTA from '../../assets/images/partners/Google Tag Manager.svg';
import PA from '../../assets/images/partners/Political AdChoices.svg';
import AdChoices from '../../assets/images/partners/AdChoices.svg';
import AIC from '../../assets/images/partners/Advertising ID Consortium.svg';
import contactUsIcon from '../../assets/images/contactUs-icon.png'
import whatsAppIcon from '../../assets/images/whatsapp-icon.png'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function Footer() {

  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = () => {
    setSubscribed(true);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact-us');
  };

  return (
    <div>
    <div className='container-fluid prograInFooterContainer'>
      <div className='container prograInFooter'>
        <div className="row">
          <div className='col-md-12'>
            <div className='brand-logo'>
              <img src={footerLogo} alt='Programmatic India pvt ltd' width="100%" />
            </div>
          </div>
          <div className='col-md-4 mb-4'>
          <h4 class="footerHeadText">Quick links</h4>
            <div className="info links pages">
              <div className="links-item">
                <p>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                  </svg>
                  <Link to="/about">About Us</Link>
                </p>
                {/* <p>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                  </svg>
                  <a href="#">Solutios</a>
                </p> */}
                {/* <p>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                  </svg>
                  <a href="#">Platform</a>
                </p> */}
                {/* <p>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                  </svg>
                  <Link to="/partners">Partners</Link>
                </p> */}
                <p>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                  </svg>
                  <Link to="/why-programmatic-india">Why Programmatic India</Link>
                </p>
                <p>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                  </svg>
                  <Link to="/contact-us">Contact Us</Link>
                </p>
              </div>
              {/* <div className="links-item">
                
              </div> */}
            </div>
          </div>
          <div className='col-md-4'>
            <h4 className='footerHeadText'>Get in touch</h4>
            <ul className='getInTouchLinks'>
              <li><i className='fa fa-location-dot'></i> <p className='footerParaText' >Office A-133/5th Floor, Wework, Spectrum Tower, Chincholi Bunder Road, Mindspace Malad west,
                Mumbai 400064<br />
                <a className='btn-small btn getRedirectionBtn' href="https://maps.app.goo.gl/rarHreP3QTX688Jw8" target='_blank'><i class="fa-solid fa-map"></i> Get Directions</a>
              </p>
              
              </li>
              <li><i className='fa fa-envelope-o'></i> info@programmaticindia.com</li>
            </ul>



            {/* <a className='footerParaText' href="">About UACL</a>
            <a className='footerParaText' href="">Careers</a>
            <a className='footerParaText' href="">Contact Us</a> */}
          </div>
          {/* <div className='col-md-3'>
            <h4 className='footerHeadText'>Legal & Privacy</h4>
            <a className='footerParaText' href="">Terms of Use</a>
            <a className='footerParaText' href="">Privacy Policy</a>
            <a className='footerParaText' href="">UACL Guidelines</a>
          </div> */}
          <div className='col-md-4'>
            <div className='partnersBrand-logo'>
              <div className='item'>
                <img src={DAA} alt='Digital Advertising Alliance' />
              </div>
              <div className='item'>
                <img src={GDPR} alt='GDPR' />
              </div>
              <div className='item'>
                <img src={CCPA} alt='CCPA' />
              </div>
              <div className='item'>
                <img src={iab} alt='iab' />
              </div>
              <div className='item'>
                <img src={iab_europ} alt='iab.europe' />
              </div>
              <div className='item'>
                <img src={GTA} alt='Google Tag Manager' />
              </div>
              <div className='item'>
                <img src={PA} alt='Political AdChoices' />
              </div>
              <div className='item'>
                <img src={AdChoices} alt='AdChoices' />
              </div>
              <div className='item'>
                <img src={AIC} alt='Advertising ID Consortium' />
              </div>
            </div>


          </div>
        </div>
      </div>
      <div className='container  prograInCopyrightContainer'>
        <div className='row'>
          <div className='col-md-8 d-flex copyright'>
            <p className='footerParaText02 text-center'>Copyright @ 2024 Programmatic India Technologies Pvt. Ltd. <span className="line">|</span><span className='br'></span> All Rights Reserved.</p>
          </div>
          <div className='col-md-4 socialmediaConatiner'>
            <div className='socialmediaLinksContainer col-md-6 d-flex gap-3'>
              <a href="https://www.linkedin.com/company/programmatic-india-technologies-pvt-ltd/" target='_blank' className=''><i className="fa-brands fa-linkedin-in footerIcons"></i></a>
              {/* <a href="" className=''><i className="fa-brands fa-facebook-f footerIcons"></i></a>
            <a href="" className='' target='_blank'><i className="fa-brands fa-instagram footerIcons"></i></a>
            <a href="" className=''><i className="fa-brands fa-x-twitter footerIcons"></i></a>
            <a href="" className=''><i className="fa-brands fa-youtube footerIcons"></i></a> */}
              <a href="https://api.whatsapp.com/send?phone=917977201885" className='' target='_blank'><i className="fa-brands fa-whatsapp footerIcons"></i></a>
            </div>
          </div>
        </div>
      </div>

      {/* fixed Button */}
      <button className='contactfl-navigation-sticky-button' onClick={handleClick}>
        <img src={contactUsIcon} alt="contactUs icon" />
        Contact<br /> us</button>
      <a href="https://api.whatsapp.com/send?phone=917977201885" target="_blank" className='whatsapp-navigation-sticky-button'>
        <img src={whatsAppIcon} alt="contactUs icon" /></a>
    </div>

    {/*  Sticky Buttons */}
    <div className="sticky-buttons">
    <Link to="contact-us" className="contact-us-btn">
    <img src={contactUsIcon} alt="contactUs icon" />
      Contact Us</Link>
    <a href="https://wa.me/917977201885" className="whatsapp-btn" target="_blank"><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
  </div>
  </div>
    
  )
}

export default Footer