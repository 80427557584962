import React from 'react';
import PSICON1 from '../../assets/images/industry/bfaicon-1.png'
import PSICON2 from '../../assets/images/industry/media-consumption-icon.png'
import PSICON3 from '../../assets/images/industry/bfaicon-2.png'
import PSICON4 from '../../assets/images/industry/bfaicon-3.png'


function BFSISec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='BFSISec boxDiv bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="cardDiv relative">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">Household Income</h3>
                                    <img src={PSICON1}
                                        alt="Capture Audience" loading="lazy" width="100%" />
                                    <div className="">
                                        <p>Target audiences based on monthly household income.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Services
                                    </h3>
                                    <img src={PSICON3}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target audiences interested in financial products and services such as banking, insurance, credit cards, retirement plan, mutual funds etc.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Visitation
                                    </h3>
                                    <img src={PSICON4}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Target visitors of competitor banks and financial institutions branches.
</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Media Consumption
                                    </h3>
                                    <img src={PSICON2}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Devices seen with Banking, Trading, Stocks related apps</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default BFSISec;
