import React from 'react';
import { Link } from 'react-router-dom';


export default function ReadyDeploy() {
    return (
    <>
    <div className='container'>
    <div className='row'>
        <div className='itembox'>
      <h2 className="display-5 fw-bold">Ready to deploy the power of <br />Programmatic India for your business?</h2>
      <Link to="/contact-us" className='main-btn btn'>ASK FOR A DEMO</Link>
      </div>
    </div>
    </div>      
</>
)
}