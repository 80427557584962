import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import AboutPageSec from '../components/Pages/AboutPageSec';

function About() {
  return (
    <>
    <Helmet>
    <title>About us | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="About us" subtitle="Programmatic India is a world class global RTB DSP with access to 1.4 Million plus publishers on the open internet (95% of all open internet publishers) and custom audience targeting capability with 91.5 crore strong audience data." />
    <AboutPageSec />

    </>
  )
}

export default About