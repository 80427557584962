import './App.css';
import './responsive.css';
import Navigation from './components/Layout/Navigation';
import Footer from './components/Layout/Footer';
import Home from './screens/Home';
import About from './screens/About';
import WhyProgrammaticIndia from './screens/WhyProgrammaticIndia';
import Contact from './screens/Contact';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import ScrollToTop from './screens/ScrollToTop';
import Political from './screens/Political';
import Partners from './screens/Partners';
import OurTechnologies from './screens/OurTechnologies';
import SelfServePlatform from './screens/SelfServePlatform';
import AudiencePlanning from './screens/AudiencePlanning';
import AudienceTargeting from './screens/AudienceTargeting';
import LocationTargeting from './screens/LocationTargeting';
import ContextualTargeting from './screens/ContextualTargeting';
import Healthcare from './screens/Healthcare';
import Travel from './screens/Travel';
import BFSI from './screens/BFSI';
import Retail from './screens/Retail';
import OOHAmbientMedia from './screens/OOHAmbientMedia';
import Optimization from './screens/Optimization';


function App() {
  return (
    <div className="App">
      {/* <Router basename="/landing-pages/programmatic_india"> */}
      <Router>
        <ScrollToTop /> {/* Add ScrollToTop */}
        <Navigation />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          ></Route>
          <Route
            path="/about"
            element={<About />}
          ></Route>
          <Route path="/why-programmatic-india" element={<WhyProgrammaticIndia />} />
          <Route
            path="/contact-us"
            element={<Contact />}
          ></Route>
          
          <Route path="/solutions/planning/audience-planning" element={<AudiencePlanning />} />
          <Route path="/solutions/targeting/audience-targeting" element={<AudienceTargeting />} />
          <Route path="/solutions/targeting/location-targeting" element={<LocationTargeting />} />
          <Route path="/solutions/targeting/contextual-targeting" element={<ContextualTargeting />} />
          <Route path="/solutions/optimization" element={<Optimization />} />
          <Route path="/platform/self-serve-platform" element={<SelfServePlatform />} />
          <Route path="/platform/our-technologies" element={<OurTechnologies />} />
          <Route path="/industry/political" element={<Political />} />
          <Route path="/industry/healthcare" element={<Healthcare />} />
          <Route path="/industry/travel" element={<Travel />} />
          <Route path="/industry/BFSI" element={<BFSI />} />
          <Route path="/industry/retail" element={<Retail />} />
          <Route path="/industry/ambient-media" element={<OOHAmbientMedia />} />
          <Route
            path="/partners"
            element={<Partners />}
          ></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;