import React from 'react'
import HeaderSlider from '../components/HeaderSlider'
import AboutSec from '../components/AboutSec'
import ReadyDeploy from '../components/ReadyDeploy'
import ATC from '../components/ATC'
import Contextual from '../components/Contextual'
import CrossDevice from '../components/CrossDevice'
import ExploreAudience from '../components/ExploreAudience'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <>
    <Helmet>
    <title>Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <section className='homePageContainer pb-0 pt-0'>
      <HeaderSlider />
    </section>
    <section className="about p-0">
      <AboutSec />
    </section>
    <section className="ReadyDeploy">
      <ReadyDeploy />
    </section>
    <section className='ExploreAudience'>
      <ExploreAudience/>
    </section>
    <section className="ATC">
      <ATC />
    </section>
    <section className='Contextual'>
      <Contextual />
    </section>
    <section className="CrossDevice gradientGrayBg">
      <CrossDevice />
    </section>
    
      </>
  )
}

export default Home