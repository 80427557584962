import React from 'react';
import PSICON1 from '../../assets/images/industry/retailicon-1.png'
import PSICON2 from '../../assets/images/industry/media-consumption-icon.png'
import PSICON3 from '../../assets/images/industry/retailicon-2.png'
import PSICON4 from '../../assets/images/industry/retailicon-3.png'


function RetailSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='RetailSec boxDiv bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="cardDiv relative">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">Retail Category </h3>
                                    <img src={PSICON1}
                                        alt="Capture Audience" loading="lazy" width="100%" />
                                    <div className="">
                                        <p>High affinity to buy grocery products, Packaged foods, Personal care, Beauty products etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Purchasing Habits
                                    </h3>
                                    <img src={PSICON3}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>High affinity to purchase products and brands based on current trends, Popularity, Premiumness, Economic, Super Premium & Luxury etc. </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Monthly Spends
                                    </h3>
                                    <img src={PSICON4}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Users based on the monthly spend capacity per category like Grocery, fashion, Personal care etc.</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="cardDiv">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="fw-bold">
                                    Media Consumption
                                    </h3>
                                    <img src={PSICON2}
                                        alt=" Analyzing Audience"
                                        loading="lazy" width="100%"
                                    />
                                    <div className="">
                                        <p>Devices seen with ecommerce apps, buying online retail products</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default RetailSec;
