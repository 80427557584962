import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide1 from '../assets/images/banner/slider-bg-img-1.png'
import Slide2 from '../assets/images/banner/banner-1.jpg'
import Slide3 from '../assets/images/banner/banner-3.jpg'
import { Link } from 'react-router-dom';



export default function HeaderSlider() {
  var settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Replace CustomPrevArrow and CustomNextArrow with your custom arrow components
    nextArrow: <CustomNextArrow />
  };
  return (
    
    <div className="sliderForHeaderContainer">
      <Slider {...settings}>
        <div className="item">
          <div className="container">
          <div className="row slider_item">
            <div className="col-md-6 banner-desc">
              <div className="">
                <h1 className="display-5 fw-bold">Helping <span>Advertisers </span><br />
                  reach their desired<br /> audience</h1>
                  <p>The media buying platform <br /> for intelligent Advertising</p>
                  <Link to="/why-programmatic-india" className="main-btn btn" alt="read more">Know More</Link>
              </div>
            </div>
            <div className="col-md-6">
              <img src={Slide1} style={{width: "100%"}} />
            </div>
          </div>
          </div>
        </div>
        
      </Slider>
        <div className="taglineText">
        <p>Audience First, High Engagement Platform</p>
        </div>
    </div>
  );
}
// Example of custom previous arrow component
function CustomPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...props.style, display: 'block' }} // Customize styles as needed
      onClick={onClick}
    />
  );
}

// Example of custom next arrow component
function CustomNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...props.style, display: 'block' }} // Customize styles as needed
      onClick={onClick}
    />
  );
}