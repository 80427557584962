import React from 'react';
import APAUAIMG from '../../assets/images/platform/APAUAIMG.png'

function AudiencePlanningSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='SSPSec bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                        <div className='discription'>
                            <div class="text-left">
                                <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Create your Audience</h2>
                            </div>

                            <p>With Programmatic India, you can create a highly qualified audience with the most up-to-date & relevant real-time audience data for any industry, business location, or business category in a privacy-safe manner. Programmatic India provides complete transparency and the newly created audience list can be activated in the Programmatic India DSP platform immediately.</p>
                            <ul className="">
                                <li className="aK6P7">
                                    
                                    <p>
                                    Specially curated audience segments
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Most up-to-date audience segments
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Privacy safe audience segments
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={APAUAIMG} alt='' width="100%" />
                            </div>
                        </div>
                    </div>


                  


                </div>
            </section>

           
        </>
    );
}

export default AudiencePlanningSec;
