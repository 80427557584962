import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import OurTechnologiesSec from '../components/Pages/OurTechnologiesSec';

function OurTechnologies() {
  return (
    <>
    <Helmet>
    <title>Our Technologies | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Our Technologies" subtitle="Our technology is one of the best in the world for data security, audience intelligence, location intelligence, contextual intelligence, and optimization." />
    <OurTechnologiesSec />

    </>
  )
}

export default OurTechnologies