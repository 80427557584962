import React from 'react';
import CrossDeviceBackImg from '../assets/images/CrossDevice-back-img.png'
import CrossDeviceFrontImg from '../assets/images/CrossDevice-front-img.png'
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CrossDevice() {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-sm-4 col-xs-12 centerElements'>
                        <div className='section-title'>
                            <h2 className='display-4 fw-bold lightgreen'>Cross-Device</h2>
                        </div>
                        <p className='mt-2 d-none d-lg-block'>Target the same users on multiple devices they use in their daily lives.</p>
                        <Link to="/contact-us" class="main-btn btn mt-2 d-none d-lg-block">ASK FOR A DEMO</Link>
                    </div>
                    <div className='col-lg-8 col-sm-8 col-xs-12'>
                        <div className="overlap-end position-relative">
                            <div className="ratio ratio-4x3">
                                <video 
                                    className="img-fluid backshadow fullBorder"
                                    autoPlay=""
                                    loop=""
                                    playsInline=""
                                    controlslist="nodownload"
                                    poster={CrossDeviceBackImg}
                                >
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming.webm"
                                        type="video/webm"
                                    />
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <div className='borderLayout top'></div>
                            </div>
                            <div className="overlap-content col-lg-7 col-6 col-xs-12">
                                <div className='videoRatio'>
                                <video
                                    className="img-fluid shadow-md fullBorder bg-primary-5"
                                    autoPlay=""
                                    loop=""
                                    muted=""
                                    playsInline=""
                                    controlslist="nodownload"
                                    poster={CrossDeviceFrontImg}
                                    >
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming-ui.webm"
                                        type="video/webm"
                                    />
                                    <source
                                        src="https://d64is2qkxbhv3.cloudfront.net/website-assets/applift/img/geofarming/vid-geofarming-ui.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <div className='borderLayout bottom'></div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='d-sm-none mt-5 detailCenter'>
                            <p className='pt-4'>Target the same users on multiple devices they use in their daily lives.</p>
                            <Link to="/contact-us" class="main-btn btn mt-2">ASK FOR A DEMO</Link>
                            </div>

                    </div>
                </div>
                <div className="py-0 py-lg-4 my-3 bg-transparent"></div>
            </div>
        </>
    )
}

