import React from 'react'
import { Helmet } from 'react-helmet';
import InnerpageBanner from '../components/Layout/InnerpageBanner';
import TravelSec from '../components/Pages/TravelSec';
function Travel() {
  return (
    <>
    <Helmet>
    <title>Travel | Programmatic India</title>
    <meta name="description" content="Your meta description" />
    </Helmet>
    <InnerpageBanner  title="Helping Travel Advertisers to reach their desired audience    
" subtitle="A Media Buying platform for Travel advertising" />
    <TravelSec />

    </>
  )
}

export default Travel